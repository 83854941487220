/*! Bulkit | 5.0 | CSS Ninja */
@import "variables";
/* ==========================================================================
Blue Theme
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Theme color variables
1. Theme gradient
2. Box shadows
3. Pageloader
=============================================================================
***/

/* ==========================================================================
0. Theme color variables
========================================================================== */
$primary: #0f7cff;
$secondary: #00edb1;
$accent: #0062dd;

/* ==========================================================================
1. Theme gradient
========================================================================== */
$webkit-hero-prime-gradient: linear-gradient(to right, $primary, darken($primary, 12%));
$hero-prime-gradient: linear-gradient(to top, $primary, darken($primary, 12%));

$webkit-hero-gradient: linear-gradient(to right, $secondary, $accent);
$hero-gradient: linear-gradient(to top, $secondary, $accent);

/* ==========================================================================
2. Pageloader
========================================================================== */
.pageloader {
    background-color: $primary !important;
}

/* ==========================================================================
3. Theme specific Hero
========================================================================== */
.hero, .section {
    &.is-theme-primary {
        background-color: $primary;
        background: $webkit-hero-prime-gradient;
        background: $hero-prime-gradient;

        .title {
            color: $white;
        }

        .subtitle {
            color: $white;
        }
    }

    &.is-theme-secondary {
        background-color: $secondary;
        background: $webkit-hero-gradient;
        background: $hero-gradient;

        .title {
            color: $white;
        }

        .subtitle {
            color: $white;
        }
    }
}
