@import "../assets/scss/abstracts/variables";
.tabs.is-centered {
    ul {
        justify-content: space-evenly !important;
    }
}

@media only screen and (max-width: $breakpoint-mobiles) {
    .tasks-table {
        overflow-x: auto;
    }
}
