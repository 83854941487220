@import "abstracts/variables";

.sidebar-icon {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
}

.icon-button {
    background: none!important;
    border: none;
    padding: 10px !important;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        i {
            color: $primary;
            stroke: $primary;
        }
    }
}

.is-flex-inline {
    display: inline-flex !important;
}

.has-gaps {
    gap: 2px;
}

.align-text-center {
    text-align: center !important;
}

p {
    word-break: break-all;
    white-space: break-spaces;
}

td, th{
    text-align: justify !important;
}

.react-datepicker-wrapper {
    width: unset !important;
}
