@import "../assets/scss/abstracts/variables";
/* ==========================================================================
1. Light mode
========================================================================== */
.multi-select-picker {

}

.loader-background {
    background: #f2f2f2 !important;
}

/* ==========================================================================
2. Dark mode
========================================================================== */
body {
    &.is-dark {

        .multi-select-picker {
            //background-color: lighten($dark-sidebar, 2%);
            //border-color: lighten($dark-sidebar, 4%);
            //color: $dark-dark-text;

        }

        .loader-background {
            background: lighten($dark-sidebar, 10%) !important;
        }
    }
}
